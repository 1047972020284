import React, { useEffect, useState } from 'react';
import './StarBackground.css';

function StarBackground() {
  // 先声明函数
  const createStars = (count) => Array.from({ length: count }, () => ({
    x: Math.random() * window.innerWidth,
    y: Math.random() * window.innerHeight,
    visible: Math.random() > 0.5  // 一半机会可见
  }));

  const [stars, setStars] = useState(() => createStars(100));  // 使用已声明的函数

  useEffect(() => {
    const intervalId = setInterval(() => {
      setStars(createStars(100));  // 使用已声明的函数
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);  // 注意这里的依赖项数组是空的，表示此effect只在组件挂载时运行一次

  return (
    <div className="stars-container">
      {stars.map((star, index) => (
        <div
          key={index}
          className="star"
          style={{
            left: `${star.x}px`,
            top: `${star.y}px`,
            opacity: star.visible ? 1 : 0  // 控制可见性
          }}
        />
      ))}
    </div>
  );
}

export default StarBackground;
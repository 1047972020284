import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { Form, Input, ConfigProvider, Button, message, Upload, Switch } from 'antd';
const dexABI = require(`@/${process.env.REACT_APP_ABI_URL}/dexAbi.json`)
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import ethImg from '@/assets/img/eth.svg'
const hostDir = process.env.REACT_APP_API_URL;
const raiseTarget = process.env.REACT_APP_RAISE_TARGET;

const CreateToken = () => {

    const [messageApi, contextHolder] = message.useMessage();
    const { open } = useWeb3Modal()
    const { address, isConnected } = useWeb3ModalAccount()

    const [isMore, setIsMore] = useState(false);
    const [isLoadong, setIsLoadong] = useState(false);

    const [file, setFile] = useState(null);
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [lastValidPreemption, setLastValidPreemption] = useState('');
    const handleValueChange = (changedValues) => {
        const { preemption } = changedValues;
        //console.log("preemption:", preemption);
        if (preemption !== undefined) {
          const validNumberRegex = /^(\d+\.?\d{0,18}|\.\d{1,18})$/;
          if (preemption === '' || validNumberRegex.test(preemption)) {
            //console.log("preemption:", "...");
            setLastValidPreemption(preemption);
            // console.log("preemption kong:", Number(""));//0
            // console.log("preemption 0.0:", Number("0.0"));//0
            // console.log("preemption 000.123:", Number("000.123"));//0.123
            // console.log("preemption 1000.123:", Number("1000.123"));
          } else {
            //console.log("preemption:", "error");
            form.setFieldsValue({ preemption: lastValidPreemption });
          }
        }
    };

    const [upOK, setUpOK] = useState(0);
    const [tradeOK, setTradeOK] = useState(0);
    useEffect(() => {
		if (upOK + tradeOK === 2) {
		  navigate('/');
		}
	}, [upOK, tradeOK]);

    // const addPair = async (data) => {
	// 	try {
	// 		const response = await fetch(hostDir + 'addPair', {
	// 			method: 'POST',
	// 			headers: {
	// 				'Content-Type': 'application/json'
	// 			},
	// 			body: JSON.stringify(data)
	// 		});
	// 		if (response.ok) {
	// 			const result = await response.json();
	// 			console.log('Server Response:', result);
	// 			messageApi.success('Add pair successfully!');
	// 			//refreshData()
	// 		} else {
	// 			throw new Error('Failed to send data to the server.');
	// 		}
	// 	} catch (error) {
	// 		console.error('Error sending data to server:', error);
	// 		messageApi.error('Error sending data to server.');
	// 	}
	// };

    const onFinish = async (values) => {
        if (!isConnected) {
            open()
            return false;
        }
        if (isLoadong) {
            return false;
        }
        if (window.ethereum) {
            try {
                setIsLoadong(true)
                const web3 = new Web3(window.ethereum);
                const accounts = await window.ethereum.request({
                    method: 'eth_requestAccounts', // Request to connect accounts
                });
                //const contractAddress = '0x6C2cbA91D7e6D77778CceC79d71ED36F610B41e6';
                const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
                const contract = new web3.eth.Contract(dexABI, contractAddress);
                let executedTx = true;

                const buyAmount = values.preemption ? Number(values.preemption) : 0;
                //console.log("buyAmount:", buyAmount);
                if(values.preemption)console.log("preemption length:", values.preemption.length);

                if(buyAmount === 0) {
                    setUpOK(0);
                    setTradeOK(1);
                    console.log("buyAmount1:", buyAmount);
                    await contract.methods.createAndBuy(values.name, values.symbol).send({ from: address, value: (0) }).then((receipt) => {
                        if (receipt.events.Create) {
                            //messageApi.info('OK!1111111111111111');
                            console.log("OK!1111111111111111", buyAmount);
                            const newTokenAddress = receipt.events.Create.returnValues.tokenAddress;
                            const createTime = receipt.events.Create.returnValues.time;
                            const bTime = 0;
                            handleSubmit({ ...values, newTokenAddress, createTime, bTime });
                            // if(receipt.events.PairCreated) {
                            //     const pair = receipt.events.PairCreated.returnValues.pair;
                            //     const sendValues = {
                            //         tokenAddress: newTokenAddress,
                            //         pair: pair
                            //     };
                            //     addPair(sendValues);
                            // }
                        }
                        //messageApi.info('OK!');
                        //console.log("OK!", buyAmount);
                    }).catch((err) => {
                        setIsLoadong(false);
                        messageApi.info('Canceled!');
                        console.log(err);
                        executedTx = false;
                    });
                    //if(executedTx) navigate('/');
                } else if((buyAmount * 10**18) < Number(raiseTarget)) {
                    setUpOK(0);
                    setTradeOK(0);
                    console.log("buyAmount2:", buyAmount);
                    await contract.methods.createAndBuy(values.name, values.symbol).send({ from: address, value: (buyAmount * 10**18) }).then((receipt) => {
                        //messageApi.info('OK2!');
                        if (receipt.events.Create) {
                            const newTokenAddress = receipt.events.Create.returnValues.tokenAddress;
                            const createTime = receipt.events.Create.returnValues.time;
                            const bTime = createTime;
                            handleSubmit({ ...values, newTokenAddress, createTime, bTime });
                            // if(receipt.events.PairCreated) {
                            //     const pair = receipt.events.PairCreated.returnValues.pair;
                            //     const sendValues = {
                            //         tokenAddress: newTokenAddress,
                            //         pair: pair
                            //     };
                            //     addPair(sendValues);
                            // }
                        }
                        if (receipt.events.Trade) {
                        	const gotValues = receipt.events.Trade.returnValues;
                        	console.log('gotValues:', gotValues);

                            let hot = 0;
                            contract.methods.getEthPoolOfToken(gotValues.tokenAddress).call()
                                .then(function (result) {
                                    console.log('balanceOf:', result);
                                    console.log(typeof result);
                                    if(Number(result)*2 >= Number(raiseTarget)) hot = 1;

                                    const sendValues = {
                                        tokenAddress: gotValues.tokenAddress,
                                        account: address,
                                        tokenAmount0: gotValues.tokenAmount0.toString(),
                                        ethAmount0: gotValues.ethAmount0.toString(),
                                        tokenAmount1: gotValues.tokenAmount1.toString(),
                                        ethAmount1: gotValues.ethAmount1.toString(),
                                        time: gotValues.time.toString(),
                                        fee: gotValues.fee.toString(),
                                        type: 1,
                                        txHash: receipt.transactionHash,
                                        hotlist: hot,
                                        ethPool: Number(result)/Number(10**18)
                                    };
                                    console.log('sendValues:', sendValues);
                                    handleTrade(sendValues);
                                }).catch(function (error) {
                                    console.error('error:', error);
                                });
                        	messageApi.success('Trade Successful!');
                        }
                    }).catch((err) => {
                        setIsLoadong(false);
                        messageApi.info('Canceled!');
                        console.log(err);
                        executedTx = false;
                    });
                    //navigate('/');
                    if(executedTx) setIsLoadong(false);
                } else {
                    // contract.methods.getEthPoolOfToken('0x014533B2ccb88Ae131dC6215637288b997680e0B').call()
                    //             .then(function (result) {
                    //                 console.log('EthPool:', result);
                    //                 console.log(typeof result);
                    //             }).catch(function (error) {
                    //                 console.error('error:', error);
                    //             });
                    // console.log("buyAmount23455:", buyAmount * 10**18);
                    // console.log("raiseTarget:", raiseTarget);
                    // console.log("hostDir:", hostDir);
                    messageApi.info('ETH amount exceeds!');
                    setIsLoadong(false);
                }
                
            } catch (err) {
                setIsLoadong(false);
                messageApi.info('Canceled!');
                console.log(err);
            }
        } else {
            console.log('Install MetaMask');
        }
    };

    const handleSubmit = async (inData) => {
        console.log("inData:", inData);
        let formData = new FormData();
        formData.append('address', inData.newTokenAddress);
        formData.append('creator', address);
        formData.append('createTime', inData.createTime);
        formData.append('busiTime', inData.bTime);
        formData.append('name', inData.name);
        formData.append('symbol', inData.symbol);
        formData.append('info', inData.description);
        formData.append('image', file);
        formData.append('preemption', inData.preemption);
        formData.append('tw', inData.tw);
        formData.append('te', inData.te);
        formData.append('we', inData.we);

        try {
            await axios.post(`${hostDir}upload`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            });
            //console.log(res);
            messageApi.success('Create Successful!');
            setIsLoadong(false);
            setUpOK(1);
        } catch (error) {
            console.error('Error sending data to server:', error);
            messageApi.error('Error sending data to server.');
        }
    };

    const handleTrade = async (data) => {
		try {
			const response = await fetch(hostDir + 'tradeData', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			});
			if (response.ok) {
				const result = await response.json();
				console.log('Server Response:', result);
				messageApi.success('Data sent to server successfully!');
                setTradeOK(1);
			} else {
				throw new Error('Failed to send data to the server.');
			}
		} catch (error) {
			console.error('Error sending data to server:', error);
			messageApi.error('Error sending data to server.');
		}
	};

    const customRequest = async (options) => {
        const { file } = options;
        setFile(file);
    };

    return (
        <div className="min-h-[100vh] select-none overscroll-y-auto">
            {contextHolder}
            <div className='flex flex-col items-center pt-20'>
                <div className="cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-white transition-colors  h-10 px-4 py-2 text-2xl text-slate-50 hover:font-bold hover:text-slate-50">
                    <Link to="/">[go back]</Link>
                </div>
                <div className='rounded-lg p-6 w-[100%] max-w-[420px] md:w-1/2 text-white'>
                    <ConfigProvider theme={{ components: { Form: { labelColor: '#60A5FA', itemMarginBottom: 14 }, Input: { colorBgContainer: '#2A2A3B', activeBorderColor: '#d9d9d9', hoverBorderColor: '#d9d9d9', colorText: '#d9d9d9', lineHeight: '2.5', colorTextPlaceholder: '#6b7280' }}, }}>
                        <Form form={form} layout='vertical' initialValues={{ layout: 'vertical', }} onFinish={onFinish} onValuesChange={handleValueChange}>
                            <Form.Item label="name" name="name" rules={[ { required: true, message: 'Please input name!', }, ]} >
                                <Input className='text-[16px] font-medium' />
                            </Form.Item>
                            <Form.Item label="symbol" name="symbol" rules={[ { required: true, message: 'Please input symbol!', }, ]} >
                                <Input className='text-[16px] font-medium' />
                            </Form.Item>
                            <Form.Item label="description" name="description" rules={[ { required: true, message: 'Please input description!', }, ]} >
                                <Input.TextArea className='text-[16px] font-medium' />
                            </Form.Item>
                            <Form.Item label="image" name="image" rules={[ { required: true, message: 'Please upload image!', }, ]} >
                                <Upload accept="image/*" customRequest={customRequest} maxCount={1} showUploadList={false} fileList={file ? [file] : []} >
                                    <Button icon={<UploadOutlined />}>Upload</Button> <span className='text-[#fff]'>{ file?.name }</span>
                                </Upload>
                            </Form.Item>
                            <Form.Item label="preemption (creator's benefit)" name="preemption" >
                                <Input className='text-[16px] font-medium' placeholder='0.0' addonAfter={<div className='flex items-center'>
                                    <div className="text-white mr-2">ETH</div>
                                    <div className='w-8 h-8'>
                                        <img className="w-8 h-8 rounded-full" alt="ETH" src={ethImg} />
                                    </div>
                                </div>} />
                            </Form.Item>
                            <div onClick={() => { setIsMore(!isMore) }} className="cursor-pointer hover:underline text-[16px] font-semibold text-[#60A5FA] w-fit mb-[14px] cursor-pointer">
                                {isMore ? 'Hide more options ↑' : 'Show more options ↓'}
                            </div>
                            
                            {isMore && <>
                                    <Form.Item label="twitter link" name="tw">
                                        <Input className='text-[16px] font-medium' placeholder="(optional)" />
                                    </Form.Item>
                                    <Form.Item label="telegram link" name="te">
                                        <Input className='text-[16px] font-medium' placeholder="(optional)" />
                                    </Form.Item>
                                    <Form.Item label="website" name="we">
                                        <Input className='text-[16px] font-medium' placeholder="(optional)" />
                                    </Form.Item>
                                </>}
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="transition-colors w-[100%] h-10 bg-[#0d6efd] rounded-md text-sm font-medium cursor-pointer flex items-center justify-center hover:bg-[#0f172ae6] text-[#F8FAFC]">
                                    {isLoadong ? 'waiting for on-chain confirm...' : 'Create coin'}
                                </Button>
                            </Form.Item>
                            <div className='text-[16px] text-[#ffffff] mt-[15px]'>Cost to deploy: only gas fee.</div>
                        </Form>
                    </ConfigProvider>
                </div>
            </div>
        </div>
    );
};

export default CreateToken;

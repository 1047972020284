import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import TokenInfo from './pages/TokenInfo';
import Trade from './pages/Trade';
import CreateToken from './pages/CreateToken';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import Layout from '@/pages/layout';

// 1. Get projectId
const projectId = 'ea63f1b38d83459feee10dc7485a6e33'

// 2. Set chains
const mainnet = [
  // {
  //   chainId: 11155111,
  //   name: 'Sepolia',
  //   currency: 'ETH',
  //   explorerUrl: 'https://sepolia.etherscan.io',
  //   rpcUrl: 'https://sepolia.infura.io/v3/bf6317872cf441e7a317cb58f79d3e10'
  // },
  // {
  //   chainId: 1,
  //   name: 'Ethereum',
  //   currency: 'ETH',
  //   explorerUrl: 'https://etherscan.io',
  //   rpcUrl: 'https://cloudflare-eth.com'
  // },
  // {
  //   chainId: 56,
  //   name: 'BNB Smart Chain',
  //   currency: 'BNB',
  //   explorerUrl: 'https://bscscan.com/',
  //   rpcUrl: 'https://bsc-dataseed.binance.org/'
  // },
  {
    chainId: 8453,
    name: 'Base Mainnet',
    currency: 'ETH',
    explorerUrl: 'https://basescan.org',
    rpcUrl: 'https://mainnet.base.org'
  }
]

// 3. Create a metadata object
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: mainnet,
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<Layout />} />
        {/* <Route path="/" element={<HomePage />} />
        <Route path="/create" element={<CreateToken />} />
        <Route path="/info" element={<TokenInfo />} />
        <Route path="/trade" element={<Trade />} />
        <Route path="*" element={<Navigate replace to="/" />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
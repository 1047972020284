import React, { useState, useEffect, useMemo } from 'react';
import "./TokenInfo.css";
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const tokenAbi = [
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "previousAdmin",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "newAdmin",
				"type": "address"
			}
		],
		"name": "AdminChanged",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "beacon",
				"type": "address"
			}
		],
		"name": "BeaconUpgraded",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "tokenAddr",
				"type": "address"
			}
		],
		"name": "buy",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "tokenNo",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "tokenAddress",
				"type": "address"
			}
		],
		"name": "Create",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "symbol",
				"type": "string"
			}
		],
		"name": "createTokenAndPair",
		"outputs": [
			{
				"internalType": "address",
				"name": "tokenAddress",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "initialize",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint8",
				"name": "version",
				"type": "uint8"
			}
		],
		"name": "Initialized",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "tokenAddr",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "sellAmount",
				"type": "uint256"
			}
		],
		"name": "sell",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "implementation",
				"type": "address"
			}
		],
		"name": "Upgraded",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newImplementation",
				"type": "address"
			}
		],
		"name": "upgradeTo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newImplementation",
				"type": "address"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "upgradeToAndCall",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "constProduct",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getTokenInfo",
		"outputs": [
			{
				"components": [
					{
						"internalType": "address",
						"name": "tokenAddress",
						"type": "address"
					},
					{
						"internalType": "string",
						"name": "name",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "symbol",
						"type": "string"
					},
					{
						"internalType": "uint256",
						"name": "tokenAmount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethAmount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "virtualEthAmount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "product",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "removed",
						"type": "bool"
					}
				],
				"internalType": "struct StarFactory.TokenInfo[]",
				"name": "info",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "proxiableUUID",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "tokenInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "tokenAddress",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "symbol",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "tokenAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "ethAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "virtualEthAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "product",
				"type": "uint256"
			},
			{
				"internalType": "bool",
				"name": "removed",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "tokenNo",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

function TokenInfo() {
  const [tokens, setTokens] = useState([]);
  //const provider = new ethers.providers.JsonRpcProvider('YOUR_BLOCKCHAIN_NODE_URL');
  //const provider = new ethers.getDefaultProvider('sepolia');
  const dexContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  //const dexContract = new ethers.Contract(dexContractAddress, tokenAbi, provider);

  const navigate = useNavigate();
  const handleTokenClick = (tokenAddress) => {
    // 使用encodeURIComponent确保URL的安全性
    const url = `/trade?tokenAddress=${encodeURIComponent(tokenAddress)}`;
    navigate(url); // React Router v6
  };

  const provider = useMemo(() => {
    return new ethers.getDefaultProvider('sepolia');
  }, []);

  const dexContract = useMemo(() => {
    return new ethers.Contract(dexContractAddress, tokenAbi, provider);
  }, [dexContractAddress, provider]);

  useEffect(() => {
    async function loadTokenAddresses() {
        /*const tokenAddresses = await dexContract.getTokenInfo();
        if(tokenAddresses && Array.isArray(tokenAddresses) && tokenAddresses.length > 0) {
          const validTokens = tokenAddresses.filter(token => token && token.tokenAddress && token.name && token.symbol);
          setTokens(validTokens);
        }*/
        try {
            const tokenAddresses = await dexContract.getTokenInfo();
            //console.log(tokenAddresses); // 查看返回的数据结构
        if (tokenAddresses && Array.isArray(tokenAddresses)) {
            const filteredTokens = tokenAddresses.filter(token => token && token.tokenAddress && token.name && token.symbol);
            setTokens(filteredTokens);
        } else {
            // 处理不符合预期的情况
            console.error('getTokenInfo did not return an array:', tokenAddresses);
        }
        } catch (error) {
            console.error('Error fetching token addresses:', error);
        }
      }

    loadTokenAddresses();
  }, [dexContract]);

  return (
	<div>
	  <h1><Link to="/">返回</Link></h1>
      <br />
	  <div className="token-grid">
		{tokens?.map((address, index) => (
			<div 
			className={`token ${index % 3 === 2 ? 'new-row' : ''}`}
			style={{ border: '1px solid black' }}
			key={address}
			onClick={() => handleTokenClick(address?.tokenAddress)} // 添加点击事件处理
			>
			<p>通证地址：{address?.tokenAddress}</p>
			<p>通证名称：{address?.name}</p>
			<p>通证符号：{address?.symbol}</p>
			</div>
		))}
	  </div>
	</div>
  );
}

export default TokenInfo;

/*return (
    <div className="token-grid">
      {tokens.map((address, index) => (
        <div className={`token ${index % 3 === 2 ? 'new-row' : ''}`} key={address}>
          <p>{address.map((item, itemIndex) => (
            <span key={itemIndex}> {item}</span>
          ))}</p>
        </div>
      ))}
    </div>
  );*/
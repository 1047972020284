// import historyProvider from "./historyProvider";

import { hostDir } from "../../../config/config";
import Data from "./data";

// import { hexMD5 } from './../../utils/md5'

// import { group, moreSelect } from './../select'

const supportedResolutions = [
  "1",
  "5",
  "15",
  "30",
  "60",
  "120",
  "240",
  "1D",
  "1W",
  "1M",
];

let page = 0;

let close = null;

let open = null;

let low = null;

let high = null;

let frist = false;

let timeNow = null;

let resol = "";

const config = {
  supported_resolutions: supportedResolutions,
};

const Datafeed = (precision, tokenAddr, ethPrice) => {
  return {
    onReady: (cb) => {
      setTimeout(() => cb(config), 0);
    },
    searchSymbols: (
      userInput,
      exchange,
      symbolType,
      onResultReadyCallback
    ) => {},
    resolveSymbol: (
      symbolName,
      onSymbolResolvedCallback,
      onResolveErrorCallback
    ) => {
      var symbol_stub = {
        name: symbolName,
        type: "crypto",
        session: "24x7",
        timezone: "Etc/UTC",
        minmov: 1,
        pricescale: Number(1).toFixed(precision).replace(".", "") * 1,
        has_intraday: true,
        intraday_multipliers: ["1", "60"],
        supported_resolution: supportedResolutions,
        volume_precision: 13,
        data_status: "streaming",
      };

      setTimeout(function () {
        onSymbolResolvedCallback(symbol_stub);
      }, 0);
    },
    getBars: function (
      symbolInfo,
      resolution,
      from,
      to,
      onHistoryCallback,
      onErrorCallback,
      firstDataRequest
    ) {
      fetch(`${hostDir}datafeed?address=${tokenAddr}&resolution=${resolution}&from=${from}&to=${to}`)
        .then(response => response.json())
        .then(data => {
            if (data.length === 0) {
                onHistoryCallback([], { noData: true });
            } else {
                onHistoryCallback(data.map((el) => {
                  console.log(el);
                    return {
                      time: el.time * 1000, //TradingView requires bar time in ms
                      low: el.low * ethPrice,
                      high: el.high * ethPrice,
                      open: el.open * ethPrice,
                      close: el.close * ethPrice,
                      volume: Number((el.volume * ethPrice).toFixed(2)),
                    };
                  }), { noData: false });
                onHistoryCallback([], { noData: true });//Avoid frequent calls
            }
        })
        .catch(error => {
            onErrorCallback(error);
        });
      //console.log(Data);
      // onHistoryCallback(
      //   Data.map((el) => {
      //     return {
      //       time: el.Time * 1000, //TradingView requires bar time in ms
      //       low: el.Low, // 最低
      //       high: el.High, // 最高
      //       open: el.Open, // 开盘价
      //       close: el.Close, // 收盘价
      //       volume: Number(el.Volume), // 定值
      //     };
      //   }),
      //   { noData: false }
      // );

      //   historyProvider
      //     .getBars(symbolInfo, page, firstDataRequest, precision, resolution)
      //     .then((bars) => {
      //       page += 1;
      //       if (bars.length) {
      //         if (page === 1) {
      //           timeNow = bars[bars.length - 1].time;
      //           open = bars[bars.length - 1].open;
      //           low = bars[bars.length - 1].low;
      //           high = bars[bars.length - 1].high;
      //         }
      //         onHistoryCallback(bars, { noData: false });
      //       } else {
      //         onHistoryCallback(bars, { noData: true });
      //       }
      //     })
      //     .catch((err) => {
      //       onErrorCallback(err);
      //     });
    },
    subscribeBars: (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback
    ) => {
      // const ws = new WebSocket('wss://quote.gqtrader.net:443')
      /**
       * token 算法简介
       * 1. 随机6位数字 random
       * 2. md5 = ((random的前两位 + random).toString())md5加密
       * 3. random + md5
       */
      // let random = Math.floor(Math.random(1000000 - 100000) * 1000000)
      // let two = Number((random / 10000).toString().slice(0, 2))
      // let token = random + hexMD5( (two + random).toString() )
      // ws.onopen = function () {
      // 	ws.send(`LOGIN 1 ${token} 0 demoforex`)
      // 	function random (min, max) {
      // 		return Math.floor(Math.random() * (max - min + 1) + min)
      // 	}
      // 	/**
      // 	 * array [0] 产品名称
      // 	 */
      // 	ws.addEventListener("message", function(event) {
      // 		let data = JSON.parse(event.data)
      // 		if (data.code === 1) {
      // 			ws.send('ALIVE')
      // 			ws.send(`SETSYMBOLS ${symbolInfo.name}`)
      // 		}
      // 		if (data.data && typeof data.data !== 'string') {
      // 			let period = (group.find(item => item.data === resol) || moreSelect.find(item => item.data === resol)).period
      // 			let time = data.data[0][3] * 1000
      // 			if (time >= timeNow + period * 60 * 1000) {
      // 				timeNow = timeNow + period * 60 * 1000
      // 				close = Number(data.data[0][1])
      // 				frist = true
      // 			}
      // 			if (frist) {
      // 				low = null
      // 				high = null
      // 				close = null
      // 				open = Number(data.data[0][1])
      // 				frist = false
      // 			}
      // 			low = Math.min(low || open, Number(data.data[0][1]))
      // 			high = Math.max(high || open, Number(data.data[0][1]))
      // 			onRealtimeCallback({
      // 				time: timeNow,
      // 				close: Number(close || data.data[0][1]),
      // 				open:  open,
      // 				high:  high,
      // 				low: low,
      // 				volume: random(5, 10)
      // 			})
      // 		}
      // 	});
      // }
    },
    unsubscribeBars: (subscriberUID) => {
      console.log("=====unsubscribeBars running");
    },
    calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
      if (resol !== resolution) {
        resol = resolution;
        page = 0;
      }
      return undefined;
    },
    getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
      //optional
      console.log("=====getMarks running");
    },
    getTimeScaleMarks: (
      symbolInfo,
      startDate,
      endDate,
      onDataCallback,
      resolution
    ) => {
      //optional
      console.log("=====getTimeScaleMarks running");
    },
    getInputValues: (id, name, type, localizedName) => {
      console.log(id, name, type);
    },
    getServerTime: (cb) => {
      console.log("=====getServerTime running");
    },
    custom_indicators_getter: function (PineJS) {},
  };
};

export default Datafeed;

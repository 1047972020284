import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Input, ConfigProvider, message } from 'antd';
import Web3 from 'web3';
import TradeTable from '@/components/trade/table'
import TradeInfo from '@/components/trade/info'
import Business from '@/components/trade/business'
import { TVChartContainer } from '@/components/TVChartContainer/index';
import { TVChartContainerH5 } from '@/components/TVChartContainer/indexH5';
const hostDir = process.env.REACT_APP_API_URL
const picDir = hostDir + "uploads/";
const scanDir = process.env.REACT_APP_HASH_URL
const dexAddr = process.env.REACT_APP_CONTRACT_ADDRESS;
const tokenABI = require(`@/${process.env.REACT_APP_ABI_URL}/tokenAbi.json`)

const PaginationControls = ({ currentPage, totalPages, onPageChange }) => {
	return (
		<div>
			<button onClick={() => onPageChange(1)} disabled={currentPage === 1} className={currentPage === 1 ? 'text-blue-300' : 'text-white'}>First</button>
			<button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} className={currentPage === 1 ? 'text-blue-300' : 'text-white'}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;&lt;&nbsp;&nbsp;&nbsp;</button>
			<span className="text-white border border-white p-1"> Page {currentPage} of {totalPages} </span>
			<button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} className={currentPage === totalPages ? 'text-blue-300' : 'text-white'}>&nbsp;&nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
			<button onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} className={currentPage === totalPages ? 'text-blue-300' : 'text-white'}>Last</button>
		</div>
	);
};

const Trade = () => {
	//const location = useLocation();
	const navigate = useNavigate();
	//const queryParams = new URLSearchParams(location.search);
	//const tokenAddress = queryParams.get('tokenAddress');
	//const creator = queryParams.get('creator');
	//const hot = queryParams.get('hot');
	//const pair = queryParams.get('pair');
	const { address } = useParams();

	const [navIndex, setNavIndex] = useState(1);
	const [tokenInfo, setTokenInfo] = useState({"address":"0x","pair":"0x123456"});
	const [tableData, setTableData] = useState([]);
	const [lpHold, setLpHold] = useState('...');
	const [cHold, setCHold] = useState('...');
	const [pairAddr, setPairAddr] = useState('...');
	const [ethPrice, setEthPrice] = useState(0);
	const [page, setPage] = useState(1);
  	const [pageSize] = useState(20);
  	const [totalPages, setTotalPages] = useState(1);

	// Verify the Ethereum address.
	const isValidAddress = (addr) => /^0x[0-9a-fA-F]{40}$/.test(addr);

	useEffect(() => {
		if (!isValidAddress(address)) {
		  navigate('/');
		}
	}, [address, navigate]);

	useEffect(() => {
		if(isValidAddress(address)) {
			getInfo();
			getTable();
		}
	}, [address, page]);

	useEffect(() => {
		getHolding();
	}, [address, tokenInfo]);

	useEffect(() => {
		fetch(`${hostDir}ethprice`)
		  .then(response => response.json())
		  .then(data => {
			if (data && data.length > 0) {
				setEthPrice(data[0].ethusd);
				console.log(data[0].ethusd);
			} else {
				console.log('No data retrieved.');
			}
		  })
		  .catch(error => console.error('Error fetching data:', error));
	  }, []);

	const getInfo = async () => {
		fetch(`${hostDir}info?address=${encodeURIComponent(address)}`)
			.then(response => response.json())
			.then(data => {
				if(data[0].address && data[0].address.length > 9) setTokenInfo(data[0]); })
			.catch(error => console.error('Error fetching info data:', error));
	}
	// const getTable = () => {
	// 	fetch(`${hostDir}tradeInfos?tokenAddress=${encodeURIComponent(address)}`)
	// 		.then(response => response.json())
	// 		.then(data => setTableData(data))
	// 		.catch(error => console.error('Error fetching data:', error));
	// }
	const getTable = () => {
		fetch(`${hostDir}tradePages?tokenAddress=${encodeURIComponent(address)}&pageSize=${encodeURIComponent(pageSize)}`)
		  .then(response => response.json())
		  .then(data => setTotalPages(data.totalPages))
		  .catch(error => console.error('Error fetching data:', error));
		fetch(`${hostDir}tradePageInfos?tokenAddress=${encodeURIComponent(address)}&page=${encodeURIComponent(page)}&pageSize=${encodeURIComponent(pageSize)}`)
		  .then(response => response.json())
		  .then(data => setTableData(data))
		  .catch(error => console.error('Error fetching data:', error));
	};

	const getHolding = async () => {
		console.log("holding test address ssss:", address);
		if (window.ethereum && tokenInfo.address.length > 9) {
			console.log("holding test address:", address);
			try {
				const web3 = new Web3(window.ethereum);
				const tokenContract = new web3.eth.Contract(tokenABI, address);
				let tokenPair = dexAddr;
				if(tokenInfo.hotlist > 5) {
					tokenPair = tokenInfo.pair;
					setPairAddr(tokenInfo.pair.substring(0, 6));
				}
				await tokenContract.methods.balanceOf(tokenPair).call()
					.then(function (result) {
						let calcAmount = BigInt(result) / (BigInt(10 ** 18) * BigInt(10 ** 6));
						//console.log('result:', result);
						//console.log('calcAmount:', calcAmount);
						if (calcAmount > 0) {
							setLpHold((Number(calcAmount) / Number(100)).toString() + ' %');
						} else {
							setLpHold('< 0.01 %');
						}
					})
					.catch(function (error) {
						console.error('error:', error);
					});

				await tokenContract.methods.balanceOf(tokenInfo.creator).call()
					.then(function (result) {
						let calcAmount = BigInt(result) / (BigInt(10 ** 18) * BigInt(10 ** 6));
						//console.log('result:', result);
						//console.log('calcAmount:', calcAmount);
						if (calcAmount > 0) {
							setCHold((Number(calcAmount) / Number(100)).toString() + ' %');
						} else {
							setCHold('< 0.01 %');
						}
					})
					.catch(function (error) {
						console.error('error:', error);
					});

			} catch (err) {
				console.error(err);
			}
		} else if(tokenInfo.address.length < 9){
			console.log('Install MetaMask');
		}
	}

	return (
		<div className="min-h-[100vh] select-none overscroll-y-auto">
			<div className='md:block hidden pt-20'>
				<div className='text-center'>
					<div className="inline-flex items-center justify-center rounded-md font-medium h-10 px-4 py-2 mb-4 text-2xl text-slate-50 hover:font-bold cursor-pointer" onClick={() => { navigate('/') }}>[go back]</div>
				</div>
				<div className='flex space-x-8 mt-4'>
					<div className='flex w-2/3 flex-col'>
						{tokenInfo?.hotlist > 2 && tokenInfo?.hotlist < 6 && <div className={`mt-[-3px] p-2 h-[40px] text-left rounded bg-white text-[#181d28] items-left font-medium text-[14px] cursor-pointer inline-block position: absolute`} >
							&nbsp;&nbsp;&nbsp;&nbsp;The trading pair will be transferred to uniswap v2 in about a day. coming soon.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</div>}
						{tokenInfo?.hotlist > 5 && <div className={`mt-[-3px] p-2 h-[40px] text-left rounded bg-white text-[#181d28] items-left font-medium text-[14px] cursor-pointer inline-block position: absolute`} >
							&nbsp;&nbsp;&nbsp;&nbsp;Bonded uniswap v2. view the coin on Gecko&nbsp;&nbsp;&nbsp;<a className="hover:underline inline-block text-red-500" href={'https://www.geckoterminal.com/zh/base/pools/' + tokenInfo?.pair} target="_blank" rel="noopener noreferrer">click here</a>&nbsp;&nbsp;
						</div>}
						{tokenInfo?.hotlist > 2 && <div><br /><br /></div>}
						<div className='flex justify-between w-[100%]'>
							<div className='text-xs text-green-300 flex items-start'>
								<div className="text-gray-400 mr-[20px]">{tokenInfo?.name}</div>
								<div className="text-gray-400 mr-[20px]">Symbol: {tokenInfo?.symbol}</div>
								<div className='mr-[20px]'>Market cap: ${Math.floor(Number(tokenInfo?.price) * 10**10 * Number(ethPrice)).toLocaleString()}</div>
							</div>
							{tokenInfo.address.length > 9 && tokenInfo.creator.length > 9 && <div className='text-sm flex items-center text-green-300'>
								<div className=''>created by 👩‍🚀</div>
								<a className="hover:underline text-sm" target="_blank" rel="noopener noreferrer" href={scanDir + 'address/' + tokenInfo.creator}>{tokenInfo.creator.substring(0, 6) + "..."}</a>
							</div>}
						</div>
						<div className='h-[400px]'>
							<TVChartContainer  tokenInfo={tokenInfo}/>
						</div>
						<TradeTable tokenInfo={tokenInfo} tableData={tableData} ethPrice={ethPrice}/>
						<div className="max-w-[800px] mx-auto grid gap-2 border border-transparent">
							<PaginationControls currentPage={page} totalPages={totalPages} onPageChange={setPage} />
						</div>
					</div>
					<div className='w-1/3'>
						<div className='w-[350px] grid gap-7'>
							<Business tokenInfo={tokenInfo} refreshData={getTable} />
							<TradeInfo tokenInfo={tokenInfo} />
							{tokenInfo.address.length > 9 && tokenInfo.creator.length > 9 && <div className='grid gap-1'>
								<div className="font-bold text-lg text-gray-400">Liquidity and creator's holding :</div>
								{tokenInfo.hotlist < 6 && <div className="flex text-sm justify-between text-gray-400"><a className="hover:underline" href={scanDir + 'token/' + address + '?a=' + dexAddr} target="_blank" rel="noopener noreferrer">🏦 {dexAddr.substring(0, 6) + "..."}(liquidity pool)</a><div>{lpHold}</div></div>}
								{tokenInfo.hotlist > 5 && <div className="flex text-sm justify-between text-gray-400"><a className="hover:underline" href={scanDir + 'token/' + address + '?a=' + tokenInfo.pair} target="_blank" rel="noopener noreferrer">🏦 {pairAddr + "..."}(liquidity pool)</a><div>{lpHold}</div></div>}
								<div className="flex text-sm justify-between text-gray-400"><a className="hover:underline" href={scanDir + 'token/' + address + '?a=' + tokenInfo.creator} target="_blank" rel="noopener noreferrer">👩‍🚀 {tokenInfo.creator.substring(0, 6) + "..."}(token creator)</a><div>{cHold}</div></div>
								<div className="flex text-sm justify-center text-gray-400"><a className="hover:underline" href={scanDir + 'token/' + address + '#balances'} target="_blank" rel="noopener noreferrer">more holding &gt;&gt;</a></div>
							</div>}
						</div>
					</div>
				</div>
			</div>
			<div className='md:hidden relative grid pb-24 justify-items-center pt-[80px]'>
				<div className='text-center'>
					<div className="inline-flex items-center justify-center rounded-md font-medium h-10 px-4 py-2 mb-4 text-2xl text-slate-50 hover:font-bold cursor-pointer" onClick={() => { navigate('/') }}>[go back]</div>
				</div>
				{navIndex == 3 && <div className='w-[100%] px-[20px]'>
					{tokenInfo.address && tokenInfo.address.length > 9 && <div>
						<div className="flex gap-3 h-fit">
							<img src={picDir + tokenInfo?.pic} className="w-32 object-contain" />
							<div>
								<div className="font-bold text-sm text-gray-400">{tokenInfo?.name}({tokenInfo?.symbol})</div>
								<div className="text-xs text-gray-400">{tokenInfo?.info}</div>
							</div>
						</div>
						<br />
					</div>}
					<Business tokenInfo={tokenInfo} refreshData={getTable} />
				</div>}
				{navIndex == 1 && <div className='h-full p-4 overflow-auto'>
					<TradeInfo tokenInfo={tokenInfo} />
				</div>}
				{navIndex == 2 && <div className='w-[100vw]'><TVChartContainerH5 tokenInfo={tokenInfo} /></div>}
				{navIndex == 4 && <div className='w-[100vw]'>
					<TradeTable tokenInfo={tokenInfo} tableData={tableData} ethPrice={ethPrice} />
					<div className="max-w-[800px] mx-auto grid gap-2 border border-transparent">
							<PaginationControls currentPage={page} totalPages={totalPages} onPageChange={setPage} />
					</div>
				</div>}
			</div>
			<div className='md:hidden md:relative fixed bottom-0 z-10 w-full flex justify-around border-t-2 border-gray-200 py-4 bg-[#5c5f66]'>
				<div className='' onClick={() => { setNavIndex(1) }}>[info]</div>
				<div className='' onClick={() => { setNavIndex(2) }}>[chart]</div>
				<div className='' onClick={() => { setNavIndex(3) }}>[buy/sell]</div>
				<div className='' onClick={() => { setNavIndex(4) }}>[txs]</div>
			</div>
		</div>
	);
};

export default Trade;

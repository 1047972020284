import React, { useState, useEffect } from 'react';
const hostDir = process.env.REACT_APP_API_URL
const scanDir = process.env.REACT_APP_HASH_URL

const TradeTable = ({tokenInfo,tableData,ethPrice}) => {
    return (
        <div className='sm:px-[0px] px-[10px]'>
            <div className='w-full text-xs sm:text-sm text-gray-400 bg-transparent rounded-lg'>
                <div className='m-1 sm:m-2 bg-[#2e303a] rounded-lg grid grid-cols-4 sm:grid-cols-6'>
                    <div className="col-span-1 p-3 font-normal text-left">account</div>
                    <div className="col-span-1 p-3 font-normal text-left">type</div>
                    <div className="col-span-1 p-3 font-normal text-left hidden md:block">{"price(USD/" + tokenInfo?.symbol + ")"}</div>
                    <div className="col-span-1 p-3 font-normal text-left sm:hidden">{"price(USD)"}</div>
                    <div className="col-span-1 p-3 font-normal text-left hidden md:block">volume(USD)</div>
                    <div className="col-span-1 p-3 font-normal text-left sm:hidden">vol(USD)</div>
                    <div className='col-span-1 p-3 font-normal text-left hidden md:block'>date</div>
                    <div className="col-span-1 p-3 font-normal text-right hidden sm:block">transaction</div>
                </div>
            </div>
            {
                tableData.length > 0 ? tableData.map((item, index) => (
                    <div key={index}>
                        <div className='w-full text-xs sm:text-sm text-gray-400 bg-transparent rounded-lg mt-[6px]'>
                            <div className='m-1 sm:m-2 bg-[#2e303a] rounded-lg grid grid-cols-4 sm:grid-cols-6 text-xs'>
                                <div className="col-span-1 p-3 font-normal text-left hidden md:block">👷
                                    <a className="hover:underline " target="_blank" rel="noopener noreferrer" href={scanDir + "address/" + item.account}>{item.account.substring(0, 6) + "..." + item.account.substring(item.account.length - 4)}</a>
                                </div>
                                <div className="col-span-1 p-3 font-normal text-left sm:hidden">
                                    <a className="hover:underline " target="_blank" rel="noopener noreferrer" href={scanDir + "address/" + item.account}>{item.account.substring(0, 6) + "..."}</a>
                                </div>
                                <div className={`col-span-1 p-3 font-normal text-left ${item.type === 1 ? 'text-green-300' : 'text-red-300'}`}>
                                    {item.type === 1 ? "buy" : item.type === 2 ? "sell" : null}
                                </div>
                                <div className="col-span-1 p-3 font-normal text-left overflow-hidden whitespace-nowrap">{(item.priceLast * ethPrice).toFixed(10)}</div>
                                <div className="col-span-1 p-3 font-normal text-left">{Number((item.volume * ethPrice).toFixed(2))}</div>
                                <div className='col-span-1 p-3 font-normal text-left hidden md:block'>{new Date(item.time * 1000).toLocaleString()}</div>
                                <div className="col-span-1 p-3 font-normal text-right hidden sm:block">
                                    <a className="hover:text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer" href={scanDir + "tx/" + item.tx}>
                                    {item.tx.substring(0, 6) + "..." + item.tx.substring(item.tx.length - 4)}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : <div className='bg-[#2e303a] m-1 sm:m-2 rounded-lg flex flex-col items-center'>
                    <img className="w-[300px]" src={require('@/assets/img/empty.png')} alt='empty' />
                    <div className='text-gray-400 mb-[30px]'>There is currently no data available</div>
                </div>
            }
        </div>
    );
};

export default TradeTable;
import React, { useState, useEffect, useRef } from 'react';
import Web3 from 'web3';
import { useLocation, useParams } from 'react-router-dom';
import { ethers } from 'ethers';
import { Input, ConfigProvider, message } from 'antd';
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import ethImg from '@/assets/img/eth.svg'
import Prompt from '@/components/prompt'
const dexABI = require(`@/${process.env.REACT_APP_ABI_URL}/dexAbi.json`)
const tokenABI = require(`@/${process.env.REACT_APP_ABI_URL}/tokenAbi.json`)
const hostDir = process.env.REACT_APP_API_URL
const raiseTarget = process.env.REACT_APP_RAISE_TARGET;

const Business = ({ tokenInfo, refreshData }) => {

	const [messageApi, contextHolder] = message.useMessage();
	const { open } = useWeb3Modal()
	const { address, chainId, isConnected } = useWeb3ModalAccount()

	const [saleStatus, setSaleStatus] = useState(1);
	const [buyStatus, setBuyStatus] = useState(true);
	const [amount, setAmount] = useState('');
	const [account, setAccount] = useState('');
	const [price, setPrice] = useState('Loading...');
	const [name, setName] = useState('');

	const buyText = 'place trade';
	const [isAuthorized, setIsAuthorized] = useState(false);
	const [buttonText, setButtonText] = useState('authorize token');
	const [tokenAddress, setTokenAddress] = useState('0x');

	const dexContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
	//const location = useLocation();
	//const queryParams = new URLSearchParams(location.search);
	//const tokenAddress = queryParams.get('tokenAddress');

	const [data, setData] = useState([]);
	const picDir = hostDir + "uploads/";
	const [modalStatus, setModalStatus] = useState(false);

	useEffect(() => {
		setAccount(address ? address : '')
	}, [address]);

	// useEffect(() => {
	// 	fetch(`${hostDir}info?address=${encodeURIComponent(tokenAddress)}`)
	// 		.then(response => response.json())
	// 		.then(data => setData(data))
	// 		.catch(error => console.error('Error fetching info data:', error));
	// }, []);

	useEffect(() => {
		const checkAuthorization = async () => {
			if (window.ethereum && account.length > 10) {
				try {
					const web3 = new Web3(window.ethereum);
					const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);
					let allowance;
					await tokenContract.methods.allowance(account, dexContractAddress).call()
						.then(function (result) {
							// Assign the result to the variable.
							allowance = result;
							if (allowance) {
								setIsAuthorized(true);
								setButtonText('place trade');
							} else {
								setIsAuthorized(false);
								setButtonText('authorize token');
							}
							//console.log('allowance:', allowance);
						})
						.catch(function (error) {
							console.error('error:', error);
						});
					
				} catch (err) {
					console.error(err);
				}
			} else {
				console.log('Install MetaMask');
			}
		};

		setTokenAddress(tokenInfo.address);
		if(tokenAddress.length > 9) checkAuthorization();
		// const interval = setInterval(checkAuthorization, 10000); // Data is refreshed every 10 seconds
		// return () => clearInterval(interval); // The timer is cleared when the component is uninstalled

	}, [account, isAuthorized, tokenInfo]); // The dependency array is empty, meaning that this effect is only run once when the component is mounted

	const setPercent = async (rate) => {
		if (window.ethereum && account.length > 10) {
			try {
				const web3 = new Web3(window.ethereum);
				const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);

				await tokenContract.methods.balanceOf(account).call()
						.then(function (result) {
							let calcAmount = BigInt(result) * BigInt(rate) / BigInt(10**20);
							console.log('balanceOf:', result);
							console.log('calcAmount:', calcAmount);
							console.log('rate:', rate);
							console.log(typeof result);
							setAmount(calcAmount);
						})
						.catch(function (error) {
							console.error('error:', error);
						});
			} catch (err) {
				console.error(err);
			}
		} else {
			console.log('Install MetaMask');
		}
	}

	const buy = async () => {
		if (window.ethereum) {
			try {
				console.log("tokenAddress:", tokenAddress);
				const web3 = new Web3(window.ethereum);
				const accounts = await window.ethereum.request({
					method: 'eth_requestAccounts', // Request to connect accounts
				});

				//const contractAddress = '0x6C2cbA91D7e6D77778CceC79d71ED36F610B41e6';
				const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
				const contract = new web3.eth.Contract(dexABI, contractAddress);

				//await contract.methods.buy(tokenAddress).send({ from: accounts[0], value: web3.utils.toWei('0.001', 'ether') });
				await contract.methods.buy(tokenAddress).send({ from: account, value: Number(amount) * 10 ** 18 }).then((receipt) => {
					if(receipt.events.Trade) {
						const gotValues = receipt.events.Trade.returnValues;
						console.log('gotValues:', gotValues);

						contract.methods.getEthPoolOfToken(gotValues.tokenAddress).call()
                                .then(function (result) {
                                    console.log('ethPool:', result);
                                    console.log(typeof result);
									const ethPool = Number(result);
									let hot = 0;
                                    if(ethPool >= Number(raiseTarget)) {
										hot = 3;
									} else if (ethPool >= Number(raiseTarget)/2) {
										hot = 1;
									}

                                    const sendValues = {
                                        tokenAddress: gotValues.tokenAddress,
                                        account: account,
                                        tokenAmount0: gotValues.tokenAmount0.toString(),
                                        ethAmount0: gotValues.ethAmount0.toString(),
                                        tokenAmount1: gotValues.tokenAmount1.toString(),
                                        ethAmount1: gotValues.ethAmount1.toString(),
                                        time: gotValues.time.toString(),
                                        fee: gotValues.fee.toString(),
                                        type: 1,
                                        txHash: receipt.transactionHash,
                                        hotlist: hot,
                                        ethPool: Number(result)/Number(10**18)
                                    };
                                    console.log('sendValues:', sendValues);
                                    handleTrade(sendValues);
                                }).catch(function (error) {
                                    console.error('error:', error);
                                });

						messageApi.success('Trade Successful!');
						//setIsLoadong(false);
					}
					if(receipt.events.PairCreated) {
						const gotValues = receipt.events.PairCreated.returnValues;
						const sendValues = {
							tokenAddress: tokenAddress,
							pair: gotValues.pair
						};
						addPair(sendValues);
					}
				}).catch((err) => {
					console.log(err);
				});
			} catch (err) {
				console.error(err);
			}
		} else {
			console.log('Install MetaMask');
		}
	};

	const sell = async () => {
		if (window.ethereum) {
			try {
				const web3 = new Web3(window.ethereum);
				const accounts = await window.ethereum.request({
					method: 'eth_requestAccounts', // Request to connect accounts
				});
				if (!isAuthorized) {
					// Processing authorization
					const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);//new ethers.Contract(tokenAddress, tokenABI, provider);
					//const tx = await tokenContract.approve(spenderAddress, ethers.utils.parseUnits(tokenAmount.toString(), 18));
					//const tx = await tokenContract.approve(dexContractAddress, 10**38);
					await tokenContract.methods.approve(dexContractAddress, ethers.parseUnits("1000000000000000000", 18)).send({ from: account }).then(() => {
						setIsAuthorized(true);
						setButtonText('place trade');
						messageApi.success('Approve Successfully!');
					}).catch((err) => {
						console.log(err);
					});
					//await tx.wait();
					//setIsAuthorized(true);
					//setButtonText('place trade');
					// setButtonText('Make Transaction');
				} else {
					//const contractAddress = '0x6C2cbA91D7e6D77778CceC79d71ED36F610B41e6';
					const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
					const contract = new web3.eth.Contract(dexABI, contractAddress);
					//await contract.methods.sell(tokenAddress, Number(amount) * 10 ** 18).send({ from: account });
					await contract.methods.sell(tokenAddress, Number(amount) * 10 ** 18).send({ from: account }).then((receipt) => {
						if (receipt.events.Trade) {
							const gotValues = receipt.events.Trade.returnValues;
							console.log('gotValues:', gotValues);
							contract.methods.getEthPoolOfToken(gotValues.tokenAddress).call()
                                .then(function (result) {
                                    console.log('ethPool:', result);
                                    console.log(typeof result);
									const hot = 0;

                                    const sendValues = {
                                        tokenAddress: gotValues.tokenAddress,
                                        account: account,
                                        tokenAmount0: gotValues.tokenAmount0.toString(),
                                        ethAmount0: gotValues.ethAmount0.toString(),
                                        tokenAmount1: gotValues.tokenAmount1.toString(),
                                        ethAmount1: gotValues.ethAmount1.toString(),
                                        time: gotValues.time.toString(),
                                        fee: gotValues.fee.toString(),
                                        type: 2,
                                        txHash: receipt.transactionHash,
                                        hotlist: hot,
                                        ethPool: Number(result)/Number(10**18)
                                    };
                                    console.log('sendValues:', sendValues);
                                    handleTrade(sendValues);
                                }).catch(function (error) {
                                    console.error('error:', error);
                                });
							messageApi.success('Trade Successful!');
							//setIsLoadong(false);
						}
					}).catch((err) => {
						console.log(err);
					});
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			console.log('Install MetaMask');
		}
	};

	const saleMethods = (type) => {
		if (!isConnected) {
            open()
            return false;
        }
		if(tokenInfo?.hotlist > 2) {
			setModalStatus(true);
			return false;
		}
		if (type == 1) {
			if (!amount) {
				messageApi.error('number null');
				return false;
			}
			buy();
		} else {
			if (!amount && isAuthorized) {
				messageApi.error('number null');
				return false;
			}
			sell();
		}
	}
	const handleTrade = async (data) => {
		try {
			// const dataToSend = {
			// 	...gotValues,
			// 	bigIntField: gotValues.bigIntField.toString(),  // BigInt to string
			// };
			//console.log('handle sendValues:', sendValues);
			const response = await fetch(hostDir + 'tradeData', {
				//const response = await fetch('http://localhost:3000/tradeData', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			});
			if (response.ok) {
				const result = await response.json();
				console.log('Server Response:', result);
				messageApi.success('Data sent to server successfully!');
				refreshData()
			} else {
				throw new Error('Failed to send data to the server.');
			}
		} catch (error) {
			console.error('Error sending data to server:', error);
			messageApi.error('Error sending data to server.');
		}
	};
	const addPair = async (data) => {
		try {
			const response = await fetch(hostDir + 'addPair', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			});
			if (response.ok) {
				const result = await response.json();
				console.log('Server Response:', result);
				messageApi.success('Add pair successfully!');
				refreshData()
			} else {
				throw new Error('Failed to send data to the server.');
			}
		} catch (error) {
			console.error('Error sending data to server:', error);
			messageApi.error('Error sending data to server.');
		}
	};

	return (
		<>
			{contextHolder}
			<div className='w-[100%] bg-[#2e303a] p-4 rounded-lg border border-none text-gray-400 grid gap-4 sm:mb-[0px] mb-[16px]'>
			    <Prompt modalStatus={modalStatus} handleClose={() => { setModalStatus(false) }} />
				<div className='grid grid-cols-2 gap-2 mb-4'>
					<button className={`p-2 text-center rounded ${saleStatus == 1 ? 'bg-green-400 text-[#181d28]' : 'bg-gray-800 text-grey-600'} `} onClick={() => { setSaleStatus(1); setAmount(''); }}>Buy</button>
					<button className={`p-2 text-center rounded ${saleStatus == 2 ? 'bg-green-400 text-[#181d28]' : 'bg-gray-800 text-grey-600'} `} onClick={() => { setSaleStatus(2); setAmount(''); }}>Sell</button>
				</div>
				<div className='flex flex-col items-center rounded-md relative bg-[#2e303a]'>
					<ConfigProvider
						theme={{
							components: {
								Input: {
									colorBgContainer: '#2e303a',
									activeBorderColor: '#d9d9d9',
									hoverBorderColor: '#d9d9d9',
									colorText: '#d9d9d9',
									lineHeight: '2.5',
									colorTextPlaceholder: '#6b7280'
								}
							},
						}}
					>
						{saleStatus == 1 &&
							<Input value={amount} onChange={(e) => setAmount(e.target.value)} className='text-[16px] font-medium' placeholder='0.0' addonAfter={<div className='flex items-center'>
								<div className="text-white mr-2">{!buyStatus ? tokenInfo?.symbol : 'ETH'}</div>
								<div className='w-8 h-8'>
									<img className="w-8 h-8 rounded-full" alt="ETH" src={!buyStatus ? picDir + tokenInfo?.pic : ethImg} />
								</div>
							</div>} />}
						{saleStatus == 2 &&
							<Input value={amount} onChange={(e) => setAmount(e.target.value)} className='text-[16px] font-medium' placeholder='0.0' addonAfter={<div className='flex items-center'>
								<div className="text-white mr-2">{tokenInfo?.symbol}</div>
								<div className='w-8 h-8'>
									<img className="w-8 h-8 rounded-full" alt="ETH" src={picDir + tokenInfo?.pic} />
								</div>
							</div>} />}
					</ConfigProvider>
					{
						saleStatus == 1 && <div className='w-[100%] flex items-start bg-[#2e303a] mt-2 p-1 rounded-lg'>
							<button onClick={() => {setAmount('')}} className="text-xs py-1 -ml-1 px-2 rounded bg-[#181D28] text-gray-400 hover:bg-gray-800 hover:text-gray-300">reset 0</button>
							<button onClick={() => {setAmount(0.03)}} className="text-xs py-1 px-2 ml-1 rounded bg-[#181D28] text-gray-400 hover:bg-gray-800 hover:text-gray-300">0.03 ETH</button>
						</div>
					}
					{
						saleStatus == 2 && <div className='w-[100%] flex items-start bg-[#2e303a] mt-2 p-1 rounded-lg'>
							<button onClick={() => {setAmount('')}} className="text-xs py-1 -ml-1 px-2 rounded bg-[#181D28] text-gray-400 hover:bg-gray-800 hover:text-gray-300">reset 0</button>
							<button onClick={() => {setPercent(10)}} className="text-xs py-1 px-2 ml-1 rounded bg-[#181D28] text-gray-400 hover:bg-gray-800 hover:text-gray-300">10%</button>
							<button onClick={() => {setPercent(20)}} className="text-xs py-1 px-2 ml-1 rounded bg-[#181D28] text-gray-400 hover:bg-gray-800 hover:text-gray-300">20%</button>
							<button onClick={() => {setPercent(50)}} className="text-xs py-1 px-2 ml-1 rounded bg-[#181D28] text-gray-400 hover:bg-gray-800 hover:text-gray-300">50%</button>
							<button onClick={() => {setPercent(100)}} className="text-xs py-1 px-2 ml-1 rounded bg-[#181D28] text-gray-400 hover:bg-gray-800 hover:text-gray-300">100%</button>
						</div>
					}
				</div>
				<div className={`mt-[-3px] p-2 h-[40px] text-center rounded bg-green-400 text-[#181d28] items-center font-medium text-[14px] cursor-pointer `} onClick={() => { saleMethods(saleStatus) }}>{saleStatus == 1 && buyText}{saleStatus == 2 && buttonText}</div>
			</div>
		</>
	);
};

export default Business;
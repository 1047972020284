export default {
    // 输出等级: 0-no, 1-error, 2-warning, 3-info, 4-debug, 5-log
    level: 5,
    // 输出模式: 0-default, 1-normal, 2-random
    mode: 1,
    // 是否输出图标
    hasIcon: false,
    // 是否打印函数名和所在文件行号
    isPrintLine: true,
    // 图标
    icons: ['🌵', '🎍', '🐾', '🌀', '🐚', '🥝', '🥜', '🥕', '🥒', '🌽', '🍒', '🍅', '🍑', '🍋', '🍈', '🌶', '🌰', '🍠', '🍆', '🍄', '🍐', '🍌', '🍍', '🍇', '🍏', '🍓', '🍎', '🍊', '🐴', '🐗', '🦄', '🐑', '🐶', '🐔', '🐼', '🐒', '🌝', '💄', '💋', '👠', '👗', '👙', '🧣', '🍰', '🍭', '🍳', '🎄', '🎱', '⚽', '🏀', '🎵', '🚄', '⭕', '❌', '❓', '❗', '💯'],
    // 标准颜色
    colors: {
        error: '#f7630c',
        warning: '#ca5010',
        info: '#0078d7',
        debug: '#13a10e',
        log: '#1f1f1f'
    },
    // 获取随机图标
    randomIcon: function () {
        return this.icons[Math.floor(Math.random() * this.icons.length)]
    },
    // 获取随机颜色
    randomColor: function () {
        const r = Math.floor(Math.random() * 256)
        const g = Math.floor(Math.random() * 256)
        const b = Math.floor(Math.random() * 256)
        // 返回随机生成的颜色
        return `rgb(${r}, ${g}, ${b})`
    },
    // 默认打印
    printDefault: function (tag, args) {
        console.log(tag, ...args)
    },
    // 标准打印
    printNormal: function (tag, args) {
        console.log(`%c ${tag} : `, `color: ${this.colors[tag]}`, ...args)
    },
    // 随机打印
    printRandom: function (tag, args) {
        const icon = this.randomIcon()
        const bgColor = this.randomColor()
        const color = this.randomColor()
        console.log(`%c ${icon}`, `font-size:20px;background-color: ${bgColor};color: ${color};`, tag + ' : ', ...args)
    },
    print: function (tag, args) {
        if (this.isPrintLine) {
            // 获取函数名和行号
            const err = new Error()
            // console.log(err.stack)
            const stack = err.stack.split('\n').slice(3).map(line => line.trim())
            const caller = stack[0].match(/at (.+) \(/) ? stack[0].match(/at (.+) \(/)[1] : stack[0].match(/at (.+):\d+:/)[1]
            const fileLine = stack[0].match(/\(.*\/(.+)\)/) ? stack[0].match(/\(.*\/(.+)\)/)[1] : stack[0].match(/(\d+:\d+)/)[1]
            // console.log(`${caller} (${fileLine}):\n`)

            // args.push(`\n at : ${caller} (${fileLine})`)
        }

        switch (this.mode) {
            case 0: {
                this.printDefault(tag, args)
                break
            }
            case 1: {
                this.printNormal(tag, args)
                break
            }
            case 2: {
                this.printRandom(tag, args)
                break
            }
        }
    },
    error: (function (oriLogFunc) {
        return function (...args) {
            const tag = 'error'
            if (this.level >= 1) {
                // oriLogFunc.call(console, 'error : ', args)
                this.print(tag, args)
            }
        }
    })(console.log),

    warning: (function (oriLogFunc) {
        return function (...args) {
            const tag = 'warning'
            if (this.level >= 2) {
                // oriLogFunc.call(console, 'warning : ', args)
                this.print(tag, args)
            }
        }
    })(console.log),

    info: (function (oriLogFunc) {
        return function (...args) {
            const tag = 'info'
            if (this.level >= 3) {
                // oriLogFunc.call(console, 'info : ', args)
                this.print(tag, args)
            }
        }
    })(console.log),

    debug: (function (oriLogFunc) {
        return function (...args) {
            const tag = 'debug'
            if (this.level >= 4) {
                // oriLogFunc.call(console, 'debug : ', ...args)
                this.print(tag, args)
            }
        }
    })(console.log),

    log: (function (oriLogFunc) {
        return function (...args) {
            const tag = 'log'
            if (this.level >= 5) {
                // oriLogFunc.call(console, 'log : ', ...args)
                this.print(tag, args)
            }
        }
    })(console.log)
}


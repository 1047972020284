import React, { useState, useEffect, useRef } from 'react';

const Popup = ({handleClose,...prop}) => {

    useEffect(() => {
        if (prop.modalStatus) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [prop.modalStatus]);

    return (
        <>
            {
                prop.modalStatus && <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="fixed inset-0 bg-black/80 transition-opacity"></div>
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <div className="relative transform overflow-hidden rounded-lg bg-[#181D28] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg border border-slate-200 text-white">
                                <div className="bg-primary p-[16px]">
                                    <div className="flex flex-col space-y-1.5 sm:text-left text-center mb-[20px]">
                                        <h2 id="radix-:r1:" className="text-lg font-semibold leading-none tracking-tight text-center">How it works</h2>
                                    </div>
                                    <div><span className="text-yellow-300 bold">To The Moon</span> prevents rugs by making sure that all created tokens are safe. Each coin is a <span className="text-green-300 bold">fair-launch</span> with <span className="text-blue-300">no presale</span> and <span className="text-orange-300">no team allocation.</span>
                                    </div>
                                    <div className="bg-primary text-white p-4 space-y-4 text-left">
                                        <div className="text-gray-300">step 1: pick a coin that you like.</div>
                                        <div className="text-gray-300">step 2: buy the coin on the trading page.</div>
                                        <div className="text-gray-300">step 3: sell at any time to lock in your profits or losses.</div>
                                        <div className="text-gray-300">step 4: until it reaches a market cap of $65k.</div>
                                        <div className="text-gray-300">step 5: liquidity will be deposited in uniswap.</div>
                                    </div>
                                    <div className='hover:font-bold text-center mb-[10px] cursor-pointer' onClick={handleClose}>[I'm ready to the moon]</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Popup;
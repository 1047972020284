import React, { useState, useEffect } from 'react';
const hostDir = process.env.REACT_APP_API_URL
const picDir = hostDir + "uploads/";
const raiseTarget = process.env.REACT_APP_RAISE_TARGET;

const TradeInfo = ({ tokenInfo }) => {

    const [uniProgress, setUniProgress] = useState(0);
    const [hotProgress, setHotProgress] = useState(0);

    useEffect(() => {
        if(tokenInfo && tokenInfo.ethPool > 0) {
            let progressCalc = 0
            if(tokenInfo.hotlist > 5) {
                progressCalc = 100;
                setUniProgress(progressCalc);
            } else {
                progressCalc = Number((Number(tokenInfo.ethPool) * 10**20 / Number(raiseTarget)).toPrecision(6));
                console.log("progressCalc", progressCalc);
                if(progressCalc < 0.0001) progressCalc = 0;
                if(progressCalc > 100) progressCalc = 100;
                setUniProgress(progressCalc);
            }

            let hotPro = progressCalc * 2;
            if(hotPro > 100) hotPro = 100;
            setHotProgress(hotPro);
        } else if (tokenInfo && tokenInfo.hotlist > 5) {
            setUniProgress(100);
        }

	}, [tokenInfo]);

    return (
        <>
            <div className="w-[350px] bg-transparent text-gray-400 rounded-lg border border-none grid gap-4">
                <div className="flex gap-4">
                    {tokenInfo?.tw && <div className="flex text-lg justify-between text-gray-400">
                        <a className="hover:underline" href={tokenInfo?.tw} target="_blank" rel="noopener noreferrer">[twitter]</a>
                    </div>}
                    {tokenInfo?.te && <div className="flex text-lg justify-between text-gray-400">
                        <a className="hover:underline" href={tokenInfo?.te} target="_blank" rel="noopener noreferrer">[telegram]</a>
                    </div>}
                    {tokenInfo?.we && <div className="flex text-lg justify-between text-gray-400">
                        <a className="hover:underline" href={tokenInfo?.we} target="_blank" rel="noopener noreferrer">[website]</a>
                    </div>}
                </div>
                {tokenInfo.address && tokenInfo.address.length > 9 && <div className="flex gap-3 h-fit">
                    <img src={picDir + tokenInfo?.pic} className="w-32 object-contain" />
                    <div>
                        <div className="font-bold text-sm">{tokenInfo?.name}({tokenInfo?.symbol})</div>
                        <div className="text-xs text-gray-400">{tokenInfo?.info}</div>
                    </div>
                </div>}
                
                <div className="grid gap-2"><div className="text-sm text-gray-400 mb-1">swap progress: {uniProgress}%</div>
                    <div aria-valuemax="100" aria-valuemin="0" role="progressbar" data-state="indeterminate" data-max="100" className="relative h-4 overflow-hidden rounded-full dark:bg-slate-800 w-full bg-gray-700">
                        <div data-state="indeterminate" data-max="100" className="h-full w-full flex-1 bg-green-300 transition-all" style={{ width: `${uniProgress}%` }}></div></div>
                </div>
                <div className="text-xs text-gray-400">When the raised assets reaches [3 eth], at this time market cap (≈ $65,000) exceeds [15 eth], all the liquidity from the bonding curve will be deposited into Uniswap and burned. Progression increases as the price goes up.<br />
                </div>
                {tokenInfo?.crowned === 0 && <div className="grid gap-2">
                    <div className="text-sm text-gray-400">market leading progress: {hotProgress}%</div>
                    <div aria-valuemax="100" aria-valuemin="0" role="progressbar" data-state="determinate" data-max="100" className="relative h-4 overflow-hidden rounded-full dark:bg-slate-800 w-full bg-gray-700">
                        <div data-state="determinate" data-max="100" className="h-full bg-yellow-500 transition-all" style={{ width: `${hotProgress}%` }}></div>
                    </div>
                </div>}
                {tokenInfo?.crowned > 0 && <div className="text-yellow-500 font-bold">👑 Crowned market leading token at {new Date(tokenInfo?.crowned * 1000).toLocaleString()}</div>}
            </div>
        </>
    );
};

export default TradeInfo;
import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./HomePage";
import Trade from "./Trade";
import TokenInfo from './TokenInfo';
import CreateToken from './CreateToken';
import PersonalPoint from "./PersonalPoint";
import StarBackground from './StarBackground';
import Header from "@/components/header";

const App = () => {
    return (
        <div className="layout">
            <Header />
            <StarBackground />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/create" element={<CreateToken />} />
                <Route path="/trade" element={<Trade />} />
                <Route path="/info" element={<TokenInfo />} />
                <Route path="/point" element={<PersonalPoint />} />
                <Route path="/:address" element={<Trade />} />
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
        </div>
    );
};

export default App;
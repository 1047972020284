import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import { widget } from "../../charting_library/charting_library.min";
import newWidget from "../../charting_library/datafeed/bitrade.js";
import Datafeed from './api'
import { useLocation } from 'react-router-dom';
const wsDir = process.env.REACT_APP_WS_URL
const hostDir = process.env.REACT_APP_API_URL
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

import axios from "axios";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export const TVChartContainer = ({ tokenInfo }) => {
  const chartContainerRef = useRef();

  //const location = useLocation();
	//const queryParams = new URLSearchParams(location.search);
	//const tokenAddress = queryParams.get('tokenAddress');
  const address = tokenInfo.address;
  const symbol = tokenInfo.symbol;
  const [ethPrice, setEthPrice] = useState(3380.718);

  const [count, setCount] = useState(0);
  let ws = null;
  useEffect(() => {
    if(!ws) ws = new WebSocket(`${wsDir}?address=${address}`);

    ws.onopen = () => {
      test();
      console.log('Datafeed WebSocket connected');
    };

    ws.onmessage = (event) => {
      setCount(Number(event.data));
      console.log('Datafeed event.data', event.data);
    };

    ws.onclose = () => {
      console.log('Datafeed WebSocket disconnected');
    };

    ws.onerror = (event) => {
      if (event instanceof Event) {
          console.error('WebSocket error:', event);
      } else {
          const { message, code } = event;
          console.error(`WebSocket error: ${message} (Code: ${code})`);
      }
    };

    return () => {
      if(ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCount(count + 1);
  //   }, 300000); 

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    test();
  },[count]);
  useEffect(() => {
		fetch(`${hostDir}ethprice`)
		  .then(response => response.json())
		  .then(data => {
			if (data && data.length > 0) {
        const loadData = async () => {
          setEthPrice(data[0].ethusd);
				  //console.log(data[0].ethusd);
          await sleep(5000);
          console.log(data[0].ethusd);
          setCount(count + 1);
        };

        loadData();
			} else {
				console.log('No data retrieved.');
			}
		  })
		  .catch(error => console.error('Error fetching data:', error));
	  }, []);
  const test = () => {
    const widgetOptions = {
      autosize: true,
      // fullscreen: true,
      // height: '400',
      symbol: "USD/"+symbol,
      interval: "60",
      timezone: "Asia/Shanghai",
      toolbar_bg: "#18202a",
      allow_symbol_change: true,
      container_id: "tv_chart_container",
      datafeed: Datafeed(10, address, ethPrice),
      // datafeed: datafeed,
      library_path: "charting_library/",
      // locale: "zh",
      // drawings_access: { type: 'black', tools: [{ name: "Regression Trend" }] },
      // disabled_features: ["use_localstorage_for_settings", "volume_force_overlay"],
      // enabled_features: ["move_logo_to_main_pane"],
      // overrides: {
      // 	"mainSeriesProperties.style": 0,
      // 	"symbolWatermarkProperties.color": "#944",
      // 	"volumePaneSize": "tiny"
      // },
      // studies_overrides: {
      // 	"bollinger bands.median.color": "#33FF88",
      // 	"bollinger bands.upper.linewidth": 7
      // },
      debug: true,
      debug: false,
      drawings_access: {
        type: "black",
        tools: [{ name: "Regression Trend" }],
      },
      disabled_features: [
        "header_resolutions",
        "timeframes_toolbar",
        "header_symbol_search",
        "header_chart_type",
        "header_compare",
        "header_undo_redo",
        "header_screenshot",
        "header_saveload",
        //"use_localstorage_for_settings",
        //"left_toolbar",
        "volume_force_overlay",
        "widget_logo",
        "compare_symbol",
        "display_market_status",
        "go_to_date",
        "header_interval_dialog_button",
        "legend_context_menu",
        "show_hide_button_in_legend",
        "show_interval_dialog_on_key_press",
        "snapshot_trading_drawings",
        "symbol_info",
        //"header_widget",
        "edit_buttons_in_legend",
        "context_menus",
        "control_bar",
        "border_around_the_chart",
      ],
      enabled_features: [
        "disable_resolution_rebuild",
        "keep_left_toolbar_visible_on_small_screens", //防止左侧工具栏在小屏幕上消失
        "hide_last_na_study_output",
        "move_logo_to_main_pane",
        "dont_show_boolean_study_arguments",
        "use_localstorage_for_settings",
        "remove_library_container_border",
        "save_chart_properties_to_local_storage",
        "side_toolbar_in_fullscreen_mode",
        "constraint_dialogs_movement",
        "hide_left_toolbar_by_default",
        "left_toolbar",
        "same_data_requery",
        "header_in_fullscreen_mode",
      ],
      //成交量样式
      studies_overrides: {
        "volume.volume.color.0": "#fa5252",
        "volume.volume.color.1": "#12b886",
        "volume.volume.transparency": 25,
      },
      //custom_css_url: "bundles/common.css",
      supported_resolutions: [
        "1",
        "5",
        "15",
        "30",
        "60",
        "240",
        "1D",
        "1W",
        "1M",
      ],
      charts_storage_url: "http://saveload.tradingview.com",
      charts_storage_api_version: "1.1",
      client_id: "tradingview.com",
      user_id: "public_user_id",
      theme: "dark",
      overrides: {
        "paneProperties.background": "#1B1E2E",
        "paneProperties.vertGridProperties.color": "rgba(0,0,0,.1)",
        "paneProperties.horzGridProperties.color": "rgba(0,0,0,.1)",
        //"scalesProperties.textColor" : "#AAA",
        "scalesProperties.textColor": "#61688A",
        "mainSeriesProperties.candleStyle.upColor": "#12b886",
        "mainSeriesProperties.candleStyle.downColor": "#fa5252",
        "mainSeriesProperties.candleStyle.drawBorder": true,
        "mainSeriesProperties.candleStyle.wickUpColor": "#589065",
        "mainSeriesProperties.candleStyle.wickDownColor": "#AE4E54",
        "paneProperties.legendProperties.showLegend": true,

        "mainSeriesProperties.areaStyle.color1": "rgba(71, 78, 112, 0.5)",
        "mainSeriesProperties.areaStyle.color2": "rgba(71, 78, 112, 0.5)",
        "mainSeriesProperties.areaStyle.linecolor": "#9194a4",
        volumePaneSize: "small",
      },
      time_frames: [
        {
          text: "1min",
          resolution: "1",
          description: "realtime",
          title: "111",
        },
        { text: "1min", resolution: "1", description: "1min" },
        { text: "5min", resolution: "5", description: "5min" },
        { text: "15min", resolution: "15", description: "15min" },
        { text: "30min", resolution: "30", description: "30min" },
        {
          text: "1hour",
          resolution: "60",
          description: "1hour",
          title: "1hour",
        },
        {
          text: "4hour",
          resolution: "240",
          description: "4hour",
          title: "4hour",
        },
        {
          text: "1day",
          resolution: "1D",
          description: "1day",
          title: "1day",
        },
        {
          text: "1week",
          resolution: "1W",
          description: "1week",
          title: "1week",
        },
        {
          text: "1mon",
          resolution: "1M",
          description: "1mon",
        },
      ],
    };

    setTimeout(() => {
      const tvWidget = new widget(widgetOptions);

      tvWidget.onChartReady(() => {
        tvWidget
          .chart()
          .createStudy("Moving Average", false, false, [5], null, {
            "plot.color": "#EDEDED",
          });
        tvWidget
          .chart()
          .createStudy("Moving Average", false, false, [10], null, {
            "plot.color": "#ffe000",
          });
        tvWidget
          .chart()
          .createStudy("Moving Average", false, false, [30], null, {
            "plot.color": "#ce00ff",
          });
        tvWidget
          .chart()
          .createStudy("Moving Average", false, false, [60], null, {
            "plot.color": "#00adff",
          });
        tvWidget.headerReady().then(() => {
          const button = tvWidget.createButton();
          button.classList.add("apply-common-tooltip");
          button.addEventListener("click", () => {
            tvWidget.chart().setChartType(3);
            // tvWidget.setSymbol("", "1");
          });

          button.innerHTML = "Time";
        });
        tvWidget.headerReady().then(() => {
          const button = tvWidget.createButton();
          button.classList.add("apply-common-tooltip");
          button.addEventListener("click", () => {
            tvWidget.chart().setChartType(1);
            // tvWidget.setSymbol("", "1");
            tvWidget.chart().setResolution('5', function() {
              console.log('Timeframe has been changed to 5 minutes');
            });
          });

          button.innerHTML = "5m";
        });
        tvWidget.headerReady().then(() => {
          const button = tvWidget.createButton();
          button.classList.add("apply-common-tooltip");
          button.addEventListener("click", () => {
            tvWidget.chart().setChartType(1);
            // tvWidget.setSymbol("", "30");
            tvWidget.chart().setResolution('30', function() {
              console.log('Timeframe has been changed to 30 minutes');
            });
          });

          button.innerHTML = "30m";
        });
      });

      return () => {
        tvWidget.remove();
      };
    }, 200);
  };

  return (
    <div
      ref={chartContainerRef}
      id="tv_chart_container"
      className={"TVChartContainer"}
    />
  );
};

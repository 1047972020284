import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from '@/components/popup'
import './header.css';
const hostDir = process.env.REACT_APP_API_URL
const scanDir = process.env.REACT_APP_HASH_URL
const picDir = hostDir + "uploads/";
const wsDir = process.env.REACT_APP_WS_URL

const Header = () => {

    const navigate = useNavigate();
    const [modalStatus, setModalStatus] = useState(false);
    const [txInfo, setTxInfo] = useState({"creator":"0x"});
    const [tokenInfo, setTokenInfo] = useState({"creator":"0x"});
    const [txTime, setTxTime] = useState(0);
    const [count, setCount] = useState(0);
    const [animate, setAnimate] = useState(false);
    const [canimate, setCanimate] = useState(false);

    useEffect(() => {
        fetch(hostDir + 'latestInfo')
          .then(response => response.json())
          .then(data => {
            if(data.length > 0 && data[0].creator && data[0].creator.length > 10)setTxInfo(data[0])})
          .catch(error => console.error('Error fetching data:', error));
      }, [txTime]);

    useEffect(() => {
        fetch(hostDir + 'latestToken')
          .then(response => response.json())
          .then(data => {
            if(data.length > 0 && data[0].creator && data[0].creator.length > 10)setTokenInfo(data[0])})
          .catch(error => console.error('Error fetching data:', error));
      }, [count]);
    
    let ws = null;
    let heartbeatInterval = null;
    useEffect(() => {
        if(!ws) ws = new WebSocket(wsDir);
        ws.onopen = () => {
            console.log('WebSocket connected');

            // Send a heartbeat message every 30 seconds.
            heartbeatInterval = setInterval(function() { ws.send(JSON.stringify({ type: 'header ping' })); }, 30000);
        };
        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if(message.etime && message.etime > 0) setTxTime(Number(message.etime));
            if(message.count && message.count > 0) setCount(Number(message.count));
            console.log('event.data', event.data);
        };
        ws.onclose = () => {
            console.log('WebSocket disconnected');
            clearInterval(heartbeatInterval);
        };
        ws.onerror = (event) => {
            if (event instanceof Event) {
                console.error('WebSocket error:', event);
            } else {
                const { message, code } = event;
                console.error(`WebSocket error: ${message} (Code: ${code})`);
            }
        };
        return () => {
            if (ws.readyState === WebSocket.OPEN) {
                ws.close();
            }
            clearInterval(heartbeatInterval);
        };
    }, []);
    
    useEffect(() => {
        if(txTime > 0) {
            setAnimate(true);
            setTimeout(() => {
                setAnimate(false);
            }, 1000);
        }
    }, [txTime]);

    useEffect(() => {
        if(count > 0) {
            setCanimate(true);
            setTimeout(() => {
                setCanimate(false);
            }, 1000);
        }
    }, [count]);

    return (
        <div className="h-[56px] w-[100%] p-[8px] flex items-center fixed top-0 left-0 bg-[#1B1D28] z-[99]">
            <Popup modalStatus={modalStatus} handleClose={() => { setModalStatus(false) }} />
            <div className=''>
                <img className='w-[25px] cursor-pointer' src={require('@/assets/img/tothemoon.png')} onClick={() => {navigate('/')}} />
            </div>
            <div className='grid h-fit mx-[8px]'>
                <div className="flex gap-2">
                    <a className="text-sm text-white hover:underline hover:font-bold" href="https://warpcast.com/tothemoonmarket" target="_blank" rel="noopener noreferrer">[warpcast]</a>
                    <a className="text-sm text-white hover:underline hover:font-bold" href="https://t.me/tothemoon_market" target="_blank" rel="noopener noreferrer">[telegram]</a>
                </div>
                <div className="flex gap-2">
                    <a className="text-sm text-white hover:underline hover:font-bold" href="https://x.com/TTM_Market" target="_blank" rel="noopener noreferrer">[twitter]</a>
                    <button onClick={() => {setModalStatus(true)}} className="text-sm text-slate-50 hover:font-bold hover:bg-transparent hover:text-slate-50" type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:r0:" data-state="closed">[how it works]</button>
                </div>
            </div>
            <div className='hidden md:flex gap-2'>
                { txInfo?.creator.length > 10 && <div className={animate ? 'shaking' : 'bg-green-300'}><div className='p-2 rounded flex items-center gap-1 text-sm h-[36px]'>
                    <a className="hover:underline text-sm" target="_blank" rel="noopener noreferrer" href={scanDir+'address/'+txInfo?.account}>👷{txInfo?.account.substring(0, 6)}</a>
                    { txInfo?.type === 1 && <span>bought</span> }
                    { txInfo?.type === 1 && <span>{txInfo?.volume_trade}eth of</span> }
                    { txInfo?.type === 2 && <span>sold</span> }
                    <a className="hover:underline flex" target="_blank" rel="noopener noreferrer" onClick={() => {navigate(`/${encodeURIComponent(txInfo.address)}`)}}>
                        {txInfo?.symbol}<img src={picDir + txInfo?.pic} className="h-5 w-5 rounded-full" />
                    </a>
                    { txInfo?.type === 2 && <span>to {txInfo?.volume_trade}eth</span> }
                </div></div>}

                { tokenInfo?.creator.length > 10 && <div className={canimate ? 'shaking' : 'bg-blue-300'}><div className='p-2 rounded flex items-center gap-1 text-sm h-[36px]'>
                    <a className="hover:underline text-sm" target="_blank" rel="noopener noreferrer" href={scanDir+'address/'+tokenInfo?.creator}>👩‍🚀{tokenInfo?.creator.substring(0, 6)}</a>
                    <span>created</span>
                    <a className="hover:underline flex" target="_blank" rel="noopener noreferrer" onClick={() => {navigate(`/${encodeURIComponent(tokenInfo.address)}`)}}>
                        {tokenInfo?.symbol}<img src={picDir + tokenInfo?.pic} className="h-5 w-5 rounded-full" />
                    </a>
                    <span>on</span>
                    <span>{new Date(tokenInfo?.cTime * 1000).toLocaleString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'})}</span>
                </div></div>}
            </div>
            <div className='flex-1 flex justify-end'>
                {/* <ConnectMetaMask /> */}
                <w3m-button />
            </div>
        </div>
    );
};

export default Header;